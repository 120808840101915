import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

export default function SocialText2() {

  useEffect(() => { 
    AOS.init({ duration: 1000, once: true }); 
  }, []);

  return (
    <>
      <div className="SocialText2Main flex flex-col justify-center items-center mx-4 md:mx-24 lg:px-48 py-10 font-poppins bg-white">
        {/* Text Area */}
        <div className="text-left max-w-3xl mb-8" data-aos="fade-up">
          <p className="text-sm md:text-lg lg:text-sm text-black leading-loose">
          a content creator and digital marketer dedicated to crafting engaging stories for brands online.


For years, I’ve been the creative force behind the camera as a videographer and the mastermind in social media marketing and content strategy. I’ve had the privilege to collaborate with some big names you’ve definitely heard of—think Nutella, Huawei, Al Jazeera, and Turkish Airlines.

For the past 7 years, I’ve been helping brands hit their marketing targets, crafting campaigns that tell their unique stories in ways that resonate with people. Whether it’s a global brand or a local favorite, I’m all about making sure their message shines. Let’s make something awesome together!

          </p>
        </div>

        {/* Tag Area */}
        <div className="flex flex-wrap justify-left gap-2 py-6 max-w-4xl" data-aos="fade-up">
          <p className="bg-black text-white px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Copy Writing</p>
          <p className="bg-white text-black border-2 border-black px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Content Strategy</p>
          <p className="bg-black text-white px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Advertising</p>
          <p className="bg-white text-black border-2 border-black px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Campaign Management</p>
          <p className="bg-black text-white px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Branding</p>
          <p className="bg-white text-black border-2 border-black px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Social Media</p>
          <p className="bg-black text-white px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Email Marketing</p>
          <p className="bg-white text-black border-2 border-black px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Research</p>
          <p className="bg-black text-white px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Data Analysis</p>
          <p className="bg-white text-black border-2 border-black px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Digital Marketing</p>
          <p className="bg-black text-white px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Project Management</p>
          <p className="bg-white text-black border-2 border-black px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py-2 text-center text-xs md:text-base lg:text-lg rounded-lg">Brand Strategy</p>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center gap-4 w-full" data-aos="fade-up"> 
          {/* <button 
            className="px-4 py-2 md:px-6 md:py-2 lg:px-8 lg:py-3 text-black text-xs md:text-base lg:text-lg rounded-lg bg-white"
            style={{ border: '2px solid black' }}
            onClick={() => window.open('/Oğuzcan_Turan_Resume_2024.pdf', '_blank')}
          >
            See Full Resume
          </button> */}
        </div>
      </div>
    </>
  );
}
