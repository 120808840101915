import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function SocialText3() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const handleButtonClick = () => {
    navigate('/'); 
  };
  

  return (
    <>
      {/* Flex settings for mobile and web */}
      <div className=' flex flex-col md:flex-row justify-between w-full bg-white'>
        {/* Gray Box */}
        <div className='bg-[#cccccc] w-full sm:w-2/12 md:w-5/12 h-8 md:h-32'></div>
        {/* Reduced height for mobile (h-16) and normal height for desktop (md:h-32) */}
      </div>

      <div className='flex flex-col md:flex-row bg-white'>
        {/* Text Area */}
        <div className='md:mx-24 flex flex-col gap-6 w-full md:w-7/12 mt-10'>
          <h1 className='text-3xl md:text-6xl font-light leading-tight text-black' data-aos="fade-up">
            I <span className="font-bold">create</span> engaging content and empower companies with creative strategies
          </h1>

          <p className='text-base md:text-lg leading-relaxed text-black' data-aos="fade-up">
            From crafting compelling copy and managing campaigns to creating stunning visuals and executing effective digital strategies, I’ve got all your marketing needs covered. Whether you’re looking to elevate your brand with expert social media management, develop a winning content strategy, or drive results through SEO and advertising, I’m here to help you stand out digitally. Ready to take your brand to the next level?
          </p>

          {/* Button width adjustments for mobile and tablet */}
          <button
            className="custom-button1"
            onClick={handleButtonClick}
            data-aos="fade-up"
          >
            Get in Touch
          </button>
        </div>

        {/* Hidden on mobile and reduced height for gray box on mobile/tablet */}
        <div className='hidden md:block bg-[#cccccc] w-full md:w-1/12 h-[20rem] md:h-[40rem] md:mr-48'></div>
        {/* Height reduced to h-[20rem] for tablet and mobile, h-[40rem] for desktop */}
      </div>
    </>
  );
}
