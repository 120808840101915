import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import '../index.css';

export default function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Close sidebar
  };

  return (
    <header className="bg-black text-white fixed top-0 left-0 w-full z-50">
      {/* Main Navigation Bar */}
      <nav className="flex justify-between items-center px-6 md:px-24 py-4">
        {/* Left Side: Oguzcan Turan */}
        <a href="/" className="text-lg md:text-[23px] lg:text-[23px] font-light">
          Oguzcan Turan
        </a>

        {/* Right Side: Hamburger Menu */}
        <div className="flex items-center">
          <button onClick={handleSidebarToggle} className="focus:outline-none flex items-center">
            <svg
              className="w-8 h-8 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </nav>

      {/* Sidebar Menu */}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-black text-white z-50 transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 ease-in-out`}
      >
        {/* Close Button */}
        <button onClick={handleCloseSidebar} className="absolute top-4 right-4 focus:outline-none">
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>

        {/* Sidebar Links */}
        <nav className="flex flex-col items-center mt-20 space-y-6">
          <a href="/Video" className="text-2xl font-light hover:text-gray-400 transition duration-300">
            Film
          </a>
          <a href="/Social" className="text-2xl font-light hover:text-gray-400 transition duration-300">
            Social Media
          </a>
          {/* <a
            href="#services"
            className="text-2xl font-light hover:text-gray-400 transition duration-300"
            onClick={handleCloseSidebar}
          >
            Services
          </a> */}

        </nav>

        {/* Social Media Icons */}
        <div className="flex justify-center space-x-5 mt-20">
          <a href="https://www.facebook.com/oguzcan.turan" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="text-white w-6 h-6 hover:text-gray-400" />
          </a>
          <a href="https://www.instagram.com/oguzcanturan/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="text-white w-6 h-6 hover:text-gray-400" />
          </a>
          <a href="https://www.linkedin.com/in/o%C4%9Fuzcan-turan-343290218" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="text-white w-6 h-6 hover:text-gray-400" />
          </a>
        </div>
      </div>

      {/* Backdrop when sidebar is open */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={handleCloseSidebar}
        ></div>
      )}
    </header>
  );
}
